<!-- 投诉弹窗组件 -->
<template>
  <div>
    <el-dialog
        width="40%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'投诉':'投诉'"
        @update:visible="updateVisible">
      <el-form
          ref="form"
          :model="form"
          label-width="100px">

        <el-form-item label="投诉原因" label-width="100px">
          <el-select
              clearable
              v-model="form.status"
              placeholder="请选择"
              class="ele-fluid">
            <el-option label="拖车" value="1"/>
            <el-option label="搭电" value="2"/>
            <el-option label="换电" value="3"/>
          </el-select>
          <div style="margin-top: 10px;">
            <span style="font-size: 14px;font-weight: 500;color: #3f4157;margin-right: 10px;">快捷输入：</span>
            <span style="font-size: 14px;font-weight: 500;color: #FF9B05;margin-right: 10px;">客户超时投诉</span>
          </div>
        </el-form-item>

        <el-form-item label="投诉详情" label-width="100px">
          <el-input
              clearable
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5}"
              placeholder="请输入其他原因"
              v-model="form.textarea2">
          </el-input>
        </el-form-item>

        <el-form-item label="投诉服务商" label-width="100px">
          <el-button @click="Tocomplaint">请选择服务商</el-button>
        </el-form-item>
      </el-form>


      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="save">确定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
        width="40%"
        center
        :visible="complaint"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        title="投诉"
        :before-close="handleClose">
      <el-form
          ref="form"
          :model="form"
          label-width="100px">

        <el-form-item label="服务商名称" label-width="100px">
          <el-input
              :maxlength="20"
              v-model="form.name"
              placeholder="请输入服务商名称"
              clearable/>
        </el-form-item>

        <el-table
            align="center"
            border
            height="calc(100vh - 261px)"
            ref="Table"
            :data="DictList"
            tooltip-effect="dark"
            style="width: 100%"
            row-key="id"
            :headerCellStyle="headerCellStyle"
            @select="selectDict"
            @selection-change="handleSelectionChange">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              label="服务商名称"
              prop="name">
          </el-table-column>
          <el-table-column
              label="服务商地址"
              prop="address">
          </el-table-column>
        </el-table>

      </el-form>


      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="save">确定
        </el-button>
      </div>
    </el-dialog>

  </div>


</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //选择服务商
      complaint:false,

      // 表格数据
      DictList: [],
      // 总条目数
      Dicttotal: 0,
      //加载动画
      Dictloading:true,
      current:null,

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择服务商
    Tocomplaint(){
      this.complaint = true;
    },
    handleClose(){
      this.complaint = false;
      this.updateVisible(true);
    },


    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },

    //选择板块
    selectDict(selection, row){
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
      if(selection.length === 0){
        this.current = null;
      }else {
        this.current = row;
      }
      console.log(this.current);
    },
    //实现单选
    handleSelectionChange(val){
      console.log(val)
      console.log(this.$refs.Table)
      console.log("selection rows:", val);
      this.$nextTick(() => {
        if (val.length > 1) {
          this.$refs.Table.clearSelection()
          this.$refs.Table.toggleRowSelection(val.pop())
        }
      })
    },


  }
}
</script>

<style scoped lang="scss">
  .no-multiple {
    // 隐藏多选
    ::v-deep .el-table__header-wrapper .el-checkbox {
      display: none;
    }
  }
</style>
